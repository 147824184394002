const PlayerPageReducer = (state, action) => {
  switch (action.type) {
    case 'PROFILE_FETCHING':
      return {data: {}, isFetching: true};
    case 'PROFILE_UPDATE':
      return {isFetching: false, data: action.data};
    default:
      return state;
  }
};

const PlayerNewsPageReducer = (state, action) => {
  switch (action.type) {
    case 'PLAYER_NEWS_OVERWRITE':
      return action.data;
    case 'PLAYER_NEWS_FETCHING':
      return {...state, isFetching: true};
    case 'PLAYER_NEWS_UPDATE':
      const PlayerNewsPage = {...state};
      PlayerNewsPage.items = PlayerNewsPage.items.concat(action.data.items);
      PlayerNewsPage.nextUrl = action.data.nextUrl;
      PlayerNewsPage.isFetching = false;
      PlayerNewsPage.categoryFilter = action.data.categories;
      return PlayerNewsPage;
    case 'PLAYER_NEWS_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: null,
        nextUrl: null,
      };
    default:
      return state;
  }
};

const PlayerRankingsPageReducer = (state, action) => {
  switch (action.type) {
    case 'PLAYER_RANKINGS_FETCHING':
      return {...state, isFetching: true};
    case 'PLAYER_RANKINGS_UPDATE':
      return {...state, isFetching: false, data: action.data};
    default:
      return state;
  }
};

const playerStationFilter = {
  assists: false,
  topEleven: false,
  details: false,
  goals: false,
  penaltyMisses: false,
  penaltyHits: false,
  redCard: false,
  substituteIn: false,
  substituteOut: false,
  yellowCard: false,
  yellowRedCard: false,
};
const PlayerProfilePageReducer = (state, action) => {
  switch (action.type) {
    case 'PLAYER_PROFILE_STATION_MATCHES_FETCHING':
      return {
        ...state,
        matches: {
          ...state.matches,
          [action.data.key]: {
            items: [],
            error: null,
            isFetching: true,
            show: true,
            filter: playerStationFilter,
          },
        },
      };
    case 'PLAYER_PROFILE_STATION_MATCHES_UPDATE':
      return {
        ...state,
        matches: {
          ...state.matches,
          [action.data.key]: {
            isFetching: false,
            items: action.data.items,
            error: null,
            show: true,
            filter: playerStationFilter,
          },
        },
      };
    case 'PLAYER_PROFILE_STATION_MATCHES_ERROR':
      return {
        ...state,
        matches: {
          ...state.matches,
          [action.data.key]: {
            isFetching: false,
            items: [],
            error: action.data.error,
            show: true,
            filter: playerStationFilter,
          },
        },
      };
    case 'PLAYER_PROFILE_STATION_SHOW_UPDATE':
      return {
        ...state,
        matches: {
          ...state.matches,
          [action.data.key]: {
            ...state.matches[action.data.key],
            show: action.data.show,
          },
        },
      };
    case 'PLAYER_PROFILE_STATION_FILTER_UPDATE':
      return {
        ...state,
        matches: {
          ...state.matches,
          [action.data.key]: {
            ...state.matches[action.data.key],
            filter: action.data.filter,
          },
        },
      };
    default:
      return state;
  }
};

export {
  PlayerPageReducer,
  PlayerProfilePageReducer,
  playerStationFilter,
  PlayerNewsPageReducer,
  PlayerRankingsPageReducer,
};
