import React from 'react';
import styled from 'styled-components';
import {COLORS, Image, ImageBadge} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {StreamContentContainer, StreamEntity, EntityName, ImageContainer, ToTeamAlt} from 'app/styles/stream';
import {StyledTypography} from 'app/styles/content';
import {object} from 'prop-types';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import {Cached, Person, TrendingFlat} from '@mui/icons-material';
import {Emblem} from 'app/components/icons';
import {formatMonthYear} from 'app/helpers/dateHelpers';
import {TransferRole} from 'app/components/stream/activities/transferRole.const';

const ToTeamName = styled.span`
  white-space: nowrap;
`;

const ProfileTransferActivity = ({activity}) => {
  const isDeactivated = activity.activity?.player.isDeactivated;
  const ToTeamImage = activity.activity.toTeam?.image.path ? (
    <ImageContainer>
      <Image entity='emblem' width={64} height={64} src={activity.activity.toTeam.image} fit={true} />
    </ImageContainer>
  ) : (
    <ToTeamAlt>
      <Emblem style={{color: COLORS.lightGrey, fontSize: 64}} />
    </ToTeamAlt>
  );
  const ToTeamLink = activity.activity.toTeam ? (
    <LinkOnComponent to={`/team/${activity.activity.toTeam.slug}`}>{ToTeamImage}</LinkOnComponent>
  ) : (
    ToTeamImage
  );
  const FromTeamImage = activity.activity.fromTeam?.image.path ? (
    <ImageBadge value={activity.activity.fromTeam.image} position={1} fit={true} />
  ) : (
    ''
  );

  let note = activity.activity.note ? activity.activity.note : '';
  const transferRole = activity.activity.role;

  let transferRoleLabel = '';
  if (transferRole && transferRole.id !== TransferRole.isPlayer) {
    transferRoleLabel = `als ${transferRole.label} `;
  }

  if (activity.activity.timeStatus === 'timed' && activity.activity.transferDate) {
    if (note !== '') note += ' - ';
    note += 'zum ' + formatMonthYear(activity.activity.transferDate);
    // todo deprecated zur neuen saison logik, kann ab juli 2022 gelöscht werden
  } else if (activity.activity.transferTime === 'newSeason') {
    if (note !== '') note += ' - ';
    note += 'zur neuen Saison';
  }

  if (activity.activity.secondTeamAuth) {
    if (note !== '') note += ' - ';
    note += 'Zweitspielrecht';
  }

  const playerContent = (
    <>
      {FromTeamImage}
      <Image entity='player' ratio='1:1' width={64} height={64} circle={true} src={activity.activity.player.image} />
    </>
  );

  const playerImage = isDeactivated ? (
    playerContent
  ) : (
    <LinkOnComponent to={activity.activity.player ? `/player/${activity.activity.player.slug}` : ''}>
      {playerContent}
    </LinkOnComponent>
  );

  const showPlayerImage = activity.activity.player ? (
    playerImage
  ) : (
    <>
      {FromTeamImage}
      <Person style={{color: COLORS.lightGrey, fontSize: 64}} />
    </>
  );

  const playerName = (
    <EntityName isDeactivated={isDeactivated}>
      {activity.activity.player.firstName} {activity.activity.player.lastName}
    </EntityName>
  );

  const linkedPlayerName = isDeactivated ? (
    playerName
  ) : (
    <LinkOnComponent to={`/player/${activity.activity.player.slug}`}>{playerName}</LinkOnComponent>
  );

  const showPlayerName = activity.activity.player ? linkedPlayerName : <EntityName>Unbekannter Spieler</EntityName>;

  return (
    <>
      <ActivityHeader
        title='Transfer'
        subtitle={activity.activity.fromTeam?.name || activity.activity.fromAlternative}
        icon={<Cached style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.orange}
        timestamp={activity.timestamp}
      />
      <StreamContentContainer>
        <StreamEntity>
          <ImageContainer>{showPlayerImage}</ImageContainer>
          <TrendingFlat style={{color: COLORS.darkerGrey, fontSize: 24}} />
          {ToTeamLink}
        </StreamEntity>
        {showPlayerName}
        <StyledTypography variant='body2' textAlign='center' lineHeight={20}>
          von{' '}
          {activity.activity.fromTeam?.name
            ? activity.activity.fromTeam.name
            : activity.activity.fromAlternative
            ? activity.activity.fromAlternative
            : 'unbekannt'}{' '}
          nach{' '}
          <ToTeamName>
            {activity.activity.toTeam?.name
              ? activity.activity.toTeam.name
              : activity.activity.toAlternative
              ? activity.activity.toAlternative
              : 'unbekannt'}
          </ToTeamName>
        </StyledTypography>
        <StyledTypography variant='caption1' lineHeight={20}>
          {transferRoleLabel}
          {note}
        </StyledTypography>
      </StreamContentContainer>
    </>
  );
};

ProfileTransferActivity.propTypes = {
  activity: object.isRequired,
};

export default ProfileTransferActivity;
