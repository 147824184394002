import React from 'react';
import {FlexContainer} from 'app/styles/content';
import styled from 'styled-components';
import {COLORS, SIZES, Typography, Image} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {FormStanding, Emblem, ArrowDown, ArrowUp} from 'app/components/icons';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import {object} from 'prop-types';
import getFormStandingsLastResults from 'app/components/standings/FormStandings.helpers';
import {generateBasicCompetitionLink} from 'app/helpers/actionsHelpers';

const Container = styled(FlexContainer)`
  justify-content: space-between;
  padding: ${SIZES['16']};
  @media only screen and (max-width: 359px) {
    padding-left: ${SIZES['8']};
    padding-right: ${SIZES['8']};
  }
`;

const TeamStats = styled(FlexContainer)`
  flex-direction: column;
  min-width: ${SIZES['80']};
`;

const ImageContainer = styled(FlexContainer)`
  align-items: center;
  height: ${SIZES[64]};
`;

const Rank = styled(Typography)`
  &&& {
    color: ${COLORS.grey};
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LastFive = styled(FlexContainer)`
  justify-content: center;
`;

const LeagueFormStandingActivity = ({activity}) => {
  const {firstRank, lastRank, competition} = activity.activity;
  let linkUrl = `${generateBasicCompetitionLink(competition)}/standing/form`;

  const getDevelopment = (currentRank, rankWeekBefore) =>
    currentRank < rankWeekBefore ? (
      <ArrowUp style={{color: COLORS.lightGreen, fontSize: 24}} />
    ) : currentRank > rankWeekBefore && rankWeekBefore !== 0 ? (
      <ArrowDown style={{color: COLORS.lightRed, fontSize: 24}} />
    ) : null;

  const firstRankLastFive = getFormStandingsLastResults(firstRank.lastResults);
  const lastRankLastFive = getFormStandingsLastResults(lastRank.lastResults);
  const firstRankIcon = getDevelopment(firstRank.rank, firstRank.rankWeekBefore);
  const lastRankIcon = getDevelopment(lastRank.rank, lastRank.rankWeekBefore);

  return (
    <>
      <ActivityHeader
        title='Form'
        subtitle={competition.name}
        icon={<FormStanding style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.yellowGreen}
        timestamp={activity.timestamp}
        category='form'
      />
      <LinkOnComponent to={linkUrl} rel='nofollow'>
        <Container>
          <TeamStats>
            <Rank variant='display2'>
              {firstRank.rank}.{firstRankIcon}
            </Rank>
            <LastFive>{firstRankLastFive}</LastFive>
          </TeamStats>
          <ImageContainer>
            {firstRank.team?.image ? (
              <Image entity='emblem' src={firstRank.team.image} width={64} height={64} fit={true} />
            ) : (
              <Emblem style={{color: COLORS.lightGrey, fontSize: 64}} />
            )}
          </ImageContainer>
          <ImageContainer>
            {lastRank.team?.image ? (
              <Image entity='emblem' src={lastRank.team.image} width={64} height={64} fit={true} />
            ) : (
              <Emblem style={{color: COLORS.lightGrey, fontSize: 64}} />
            )}
          </ImageContainer>
          <TeamStats>
            <Rank variant='display2'>
              {lastRankIcon}
              {lastRank.rank}.
            </Rank>
            <LastFive>{lastRankLastFive}</LastFive>
          </TeamStats>
        </Container>
      </LinkOnComponent>
    </>
  );
};

LeagueFormStandingActivity.propTypes = {
  activity: object.isRequired,
};

export default LeagueFormStandingActivity;
