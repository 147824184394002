import React from 'react';
import {COLORS, Image} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {FullStreamContentContainer, ContentTitle} from 'app/styles/stream';
import {object} from 'prop-types';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import {PlayArrow} from '@mui/icons-material';

const PlaylistPublishedActivity = ({activity, isFirstElement}) => {
  return (
    <>
      <ActivityHeader
        title='Video-Playlist'
        subtitle={activity.activity.competitionName}
        icon={<PlayArrow style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.lightBlue}
        timestamp={activity.timestamp}
      />
      <LinkOnComponent to={`/tv/match/${activity.activity.slug}`}>
        <FullStreamContentContainer>
          <Image
            entity='video'
            actualWidthFactor={1}
            ratio='16:9'
            src={activity.activity.image}
            eager={isFirstElement}
          />
        </FullStreamContentContainer>
        <ContentTitle>{activity.activity.title}</ContentTitle>
      </LinkOnComponent>
    </>
  );
};

PlaylistPublishedActivity.propTypes = {
  activity: object.isRequired,
};

export default PlaylistPublishedActivity;
