import React from 'react';
import styled from 'styled-components';
import {COLORS, SIZES} from '@fupa/fupa-uikit';

const FullCardHeight = 435;
const MinCardHeight = 151;
const MediumCardHeight = 172;

const StreamWrapper = styled.div`
  margin-bottom: 0.5rem;
  background-color: ${COLORS.white};
  display: block;
  width: 100%;
  max-width: ${props => props.theme.mobileContentWidth}px;

  ${props =>
    props.theme.desktopLayout`
      height: fit-content;
      border-radius: ${SIZES['4']};
      border: 1px solid ${COLORS.lightGrey};
      max-width: ${props => (props.heroLayout ? props.theme.desktopContentWidth : props.theme.elementsWidth2Columns)}px;
      min-height: ${props => (props.minHeight ? `${props.minHeight}px` : 'none')};
      max-height: ${props => (props.maxHeight ? `${props.maxHeight}px` : 'none')};

    `}
`;

const getMinHeight = {
  NewsPublished: FullCardHeight,
  PlaylistPublished: FullCardHeight,
  GalleryPublished: FullCardHeight,
  ProfileTransfer: 211,
  CoachChanged: MinCardHeight,
  MatchDayResults: MediumCardHeight,
  MatchKickoffChanged: 191,
  MatchResult: MediumCardHeight,
  PlayerBanned: MinCardHeight,
  CompetitionSeasonFormStandings: MediumCardHeight,
  LeagueTopEleven: FullCardHeight,
  PlayerInjured: MinCardHeight,
  CompetitionScorers: 252,
  MatchEvent: MinCardHeight,
  Marketplace: MinCardHeight,
};

const getMaxHeight = {
  MatchDayResults: FullCardHeight + 14,
};

const StreamCard = ({entity, heroLayout, children, onClick}) => {
  const minHeight = getMinHeight[entity];
  const maxHeight = getMaxHeight[entity];

  return (
    <StreamWrapper onClick={onClick} heroLayout={heroLayout} minHeight={minHeight} maxHeight={maxHeight}>
      {children}
    </StreamWrapper>
  );
};

export {StreamCard};
