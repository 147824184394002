//DISTRICT_TRANSFERS_PAGE
import {api, fetchNextApiCall} from 'app/services/api';
import {catchDataError, parseLinkHeader} from 'app/helpers/apiHelpers';
import {updateHistory} from 'app/actions/historyActions';
import {updateHttpErrorSubroute} from 'app/components/error/ErrorHandlerActions';

const getDistrictTransfers = slug => {
  const streamURL = process.env.STREAM_URL;
  return api.get(`${streamURL}/districts/${slug}?type=profile_transfer`);
};

function updateTransfers(data) {
  return {
    type: 'DISTRICT_TRANSFERS_UPDATE',
    data,
  };
}

function errorTransfers(data) {
  return {
    type: 'DISTRICT_TRANSFERS_ERROR',
    data,
  };
}

function fetchingTransfers() {
  return {
    type: 'DISTRICT_TRANSFERS_FETCHING',
  };
}

function fetchDistrictTransfers(slug, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      return;
    }

    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }

    const fetchFunc = nextUrl ? fetchNextApiCall(nextUrl) : getDistrictTransfers(slug);
    dispatch(fetchingTransfers());
    return fetchFunc
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(updateTransfers({items: response.data, nextUrl}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        nextUrl ? dispatch(errorTransfers(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchDistrictTransfersSSR(slug) {
  return function (dispatch) {
    dispatch(fetchingTransfers());
    return getDistrictTransfers(slug)
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(
          updateHistory('undefined', {
            store: 'DistrictTransfersPage',
            data: {items: response.data, nextUrl, error: null, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

export {fetchDistrictTransfers, fetchDistrictTransfersSSR};
