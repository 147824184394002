import {fetchNextApiCall, api} from 'app/services/api';
import {catchDataError, parseLinkHeader} from 'app/helpers/apiHelpers';
import {marketplaceFilters} from 'app/components/stream/streamFilter.const';
import {updateHistory} from 'app/actions/historyActions';
import {updateHttpErrorSubroute} from 'app/components/error/ErrorHandlerActions';

const streamTypes = {
  news: 'news_published',
  'fupa-tv': 'playlist_published',
  gallery: 'gallery_published',
  transfer: 'profile_transfer',
  'coach-change': 'coach_changed',
  matchday: 'match_day_results',
};

const getDistrictStream = (slug, categories) => {
  const streamURL = process.env.STREAM_URL;
  let url = `${streamURL}/districts/${slug}?limit=20`;
  if (Object.keys(streamTypes).includes(categories)) {
    const type = streamTypes[categories];
    url += `&type=${type}`;
  }

  return api.get(url);
};

const createStreamQuery = (categories, filters) => {
  const filterOption = filters.find(filter => filter.param === categories);
  if (filterOption) {
    return filterOption.slug.join(',');
  } else {
    return filters.map(filter => filter.slug.map(slug => slug).join(',')).join(',');
  }
};

const getDistrictMarketplace = (districtSlug, categories) => {
  let filter = createStreamQuery(categories, marketplaceFilters);
  return api.get(`/marketplace?district=${districtSlug}&category=${filter}`);
};

//DISTRICT_NEWS_PAGE
function updateNews(data) {
  return {
    type: 'DISTRICT_NEWS_UPDATE',
    data,
  };
}

function errorNews(data) {
  return {
    type: 'DISTRICT_NEWS_ERROR',
    data,
  };
}

function fetchingNews() {
  return {
    type: 'DISTRICT_NEWS_FETCHING',
  };
}

function overwriteNews(data) {
  return {
    type: 'DISTRICT_NEWS_OVERWRITE',
    data,
  };
}

function fetchDistrictNews(slug, categories, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      return;
    }

    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }

    const fetchFunc = nextUrl ? fetchNextApiCall(nextUrl) : getDistrictStream(slug, categories);
    dispatch(fetchingNews());
    return fetchFunc
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(updateNews({items: response.data, nextUrl, categories}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        nextUrl ? dispatch(errorNews(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchDistrictNewsSSR(slug, categories) {
  return function (dispatch) {
    dispatch(fetchingNews());
    return getDistrictStream(slug, categories)
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(
          updateHistory('undefined', {
            store: 'DistrictNewsPage',
            data: {items: response.data, nextUrl, categoryFilter: categories, error: null, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

//DISTRICT_MARKETPLACE_PAGE
function updateMarketplace(data) {
  return {
    type: 'DISTRICT_MARKETPLACE_UPDATE',
    data,
  };
}

function errorMarketplace(data) {
  return {
    type: 'DISTRICT_MARKETPLACE_ERROR',
    data,
  };
}

function fetchingMarketplace() {
  return {
    type: 'DISTRICT_MARKETPLACE_FETCHING',
  };
}

function overwriteMarketplace(data) {
  return {
    type: 'DISTRICT_MARKETPLACE_OVERWRITE',
    data,
  };
}

function fetchDistrictMarketplace(slug, categories, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      return;
    }

    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }
    const fetchFunc = nextUrl ? fetchNextApiCall(nextUrl) : getDistrictMarketplace(slug, categories);
    dispatch(fetchingMarketplace());
    return fetchFunc
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(updateMarketplace({items: response.data, nextUrl, categories}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        nextUrl ? dispatch(errorMarketplace(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchDistrictMarketplaceSSR(slug, categories) {
  return function (dispatch) {
    dispatch(fetchingMarketplace());
    return getDistrictMarketplace(slug, categories)
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(
          updateHistory('undefined', {
            store: 'DistrictMarketplacePage',
            data: {items: response.data, nextUrl, categoryFilter: categories, error: null, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

export {
  fetchDistrictNewsSSR,
  fetchDistrictNews,
  fetchDistrictMarketplaceSSR,
  fetchDistrictMarketplace,
  overwriteNews,
  overwriteMarketplace,
};
