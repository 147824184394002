import React from 'react';
import {EntityName, StreamContentContainer, LeftAlignedStreamEntity, MultilineInfo} from 'app/styles/stream';
import styled from 'styled-components';
import {formatDayMonthDate, formatShortDate} from 'app/helpers/dateHelpers';
import {COLORS, Image, SIZES, Typography} from '@fupa/fupa-uikit';
import {StyledTypography} from 'app/styles/content';
import {Emblem} from 'app/components/icons';

const MarketplaceInfo = styled(MultilineInfo)`
  &&& {
    overflow: hidden;
  }
`;

const EntityHeading = styled(EntityName)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TwoLineNote = styled(Typography)`
  display: -webkit-box;
  overflow: hidden;
  height: 2.5rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  flex: 1;
  line-height: ${SIZES['20']};
`;

const MarketplaceElement = ({entity}) => {
  const EntityImage =
    entity && entity.club ? (
      entity.club.image ? (
        <Image entity='emblem' width={64} height={64} src={entity.club.image} fit={true} />
      ) : (
        <Emblem style={{color: COLORS.lightGrey, fontSize: 64}} />
      )
    ) : null;

  let info;
  if (entity.matchOptions) {
    const matchOptionTime =
      entity.matchOptions.from === entity.matchOptions.from
        ? formatShortDate(entity.matchOptions.from)
        : `${formatDayMonthDate(entity.matchOptions.from)} – ${formatShortDate(entity.matchOptions.from)}`;

    const ageGroup = `Altersklasse ${
      entity.matchOptions.ageGroup ? entity.matchOptions.ageGroup.name : 'nicht angegeben'
    }`;

    info = (
      <MarketplaceInfo>
        <EntityHeading>{entity.title}</EntityHeading>
        <StyledTypography variant='body2' lineHeight={20}>
          {ageGroup}
        </StyledTypography>
        <StyledTypography variant='caption1' lineHeight={20}>
          {matchOptionTime}
        </StyledTypography>
      </MarketplaceInfo>
    );
  } else {
    info = (
      <MarketplaceInfo>
        <EntityHeading>{entity.title}</EntityHeading>
        <TwoLineNote variant='body2'>{entity.description}</TwoLineNote>
      </MarketplaceInfo>
    );
  }

  return (
    <StreamContentContainer>
      <LeftAlignedStreamEntity>
        {EntityImage}
        {info}
      </LeftAlignedStreamEntity>
    </StreamContentContainer>
  );
};

export default MarketplaceElement;
