import React, {useEffect, useReducer} from 'react';
import {ContentAdDesktop} from 'app/styles/content';
import StreamContainer from 'app/components/stream/streamContainer';
import {teamFilters} from 'app/components/stream/streamFilter.const';
import {Helmet} from 'react-helmet-async';
import {string} from 'prop-types';
import {fetchTeamNews, overwriteNews} from 'app/routes/team/TeamPageActions';
import {useInfiniteScrollComponentState} from 'app/hooks/useInfiniteScroll';
import {useParams} from 'react-router';
import {StreamFilterFab} from 'app/components/floatingAction/StreamFilterFab';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {TeamNewsPageReducer} from 'app/routes/team/TeamPageReducer';

const streamRegex = /\/team\/[a-z0-9-]+-[a-z0-9-]+-\d+-\d+\/news\//;

const TeamNewsPage = ({defaultMetaDesc, dispatchRedux}) => {
  const {restoredData} = useRestoreData('TeamNewsPage');
  const {teamSlug, categories} = useParams();
  const initData = {
    isFetching: false,
    items: [],
    nextUrl: '',
    categoryFilter: undefined,
    error: null,
  };
  const initialState = restoredData ?? initData;
  const [state, dispatch] = useReducer(TeamNewsPageReducer, initialState);

  const loadData = (reloading, reset) => {
    const componentState = reset ? initData : state;
    return fetchTeamNews(teamSlug, categories, reloading, dispatchRedux)(dispatch, componentState);
  };

  const {items, isFetching, nextUrl, error, categoryFilter} = state;
  useInfiniteScrollComponentState(loadData, items.length);
  useFetchData(state, loadData, 'TeamNewsPage', true);

  useEffect(() => {
    // Always load new data when switching between filters
    if (categoryFilter !== categories) {
      dispatch(overwriteNews(initData));
      loadData(false, true);
    }
  }, [categories]);

  const filter = teamFilters.find(filter => filter.param === categories);
  const title = filter ? `${filter.value} - ` : 'News - ';
  const meta = [{name: 'description', content: `${title}${defaultMetaDesc}`}];

  return (
    <ContentAdDesktop
      bgColor='none'
      top='7.125rem'
      fabBtn={<StreamFilterFab filter={teamFilters} regex={streamRegex} />}>
      <Helmet title={title} meta={meta} />
      <StreamContainer
        isFetching={isFetching}
        nextPage={nextUrl}
        error={Boolean(error)}
        stream={items}
        adSlots={[]}
        noDesktopAds
      />
    </ContentAdDesktop>
  );
};

TeamNewsPage.propTypes = {
  defaultMetaDesc: string,
};

export default TeamNewsPage;
