import {fetchNextApiCall, api} from 'app/services/api';
import {catchDataError, checkApiRedirect, RedirectException, parseLinkHeader} from 'app/helpers/apiHelpers';
import {groupMatchesByKickoff, getTodayAndYesterdayAsQuery, groupByCompetition} from 'app/helpers/actionsHelpers';
import {changeHeaderText} from 'app/actions/headerActions';
import {trySettingDefaultDistrict} from 'app/actions/sessionActions';
import {updateHistory} from 'app/actions/historyActions';
import {updateHttpError, updateHttpErrorSubroute} from 'app/components/error/ErrorHandlerActions';

const getClub = club => {
  return api.get(`/clubs/${club}`);
};

const getClubMatches = (clubSlug, pointer) => {
  const {today, yesterday} = getTodayAndYesterdayAsQuery();
  const query = pointer === 'prev' ? `?to=${yesterday}&sort=desc` : `?from=${today}&fallback=true`;

  return api.get(`/clubs/${clubSlug}/seasons/current/matches${query}`);
};

const streamTypes = {
  news: 'news_published',
  'fupa-tv': 'playlist_published',
  gallery: 'gallery_published',
  transfer: 'profile_transfer',
  'coach-change': 'coach_changed',
  matchday: 'match_day_results',
  matchdelay: 'match_kickoff_changed',
  'final-score': 'match_result',
};

const getClubStream = (slug, categories) => {
  const streamURL = process.env.STREAM_URL;
  let url = `${streamURL}/clubs/${slug}?limit=18`;
  if (Object.keys(streamTypes).includes(categories)) {
    const type = streamTypes[categories];
    url += `&type=${type}`;
  }

  return api.get(url);
};

const getTeamsByClub = clubSlug => {
  return api.get(`/views/club/index/${clubSlug}`);
};

// CLUB_PAGE
function updateClub(data) {
  return {
    type: 'CLUB_UPDATE',
    data,
  };
}

function setClubHeader(dispatch, club) {
  if (!club.district || !club.name) {
    return;
  }
  let parent = {
    name: club.district.name,
    entity: 'district',
    slugs: {
      districtSlug: club.district.slug,
    },
  };

  dispatch(
    changeHeaderText({
      title: club.name,
      parent,
    })
  );
}

function fetchClubMetaDataSSR(slug) {
  return async function (dispatch, getState) {
    try {
      let response = await getClub(slug);
      const club = response.data;
      checkApiRedirect(club.slug, slug, null, null, getState);
      dispatch(updateHistory('undefined', {store: 'ClubPage', data: club}));
      setClubHeader(dispatch, club);
      // Return the Promise to make sure further handlers wait until it settles -> ssr rendered page is sent after setDefaultDistrict has finished
      return trySettingDefaultDistrict(club, dispatch, getState);
    } catch (error) {
      if (error instanceof RedirectException) {
        throw error;
      } else {
        const errorData = catchDataError(error);
        dispatch(updateHttpError(errorData));
      }
    }
  };
}

function fetchClubMetaData(slug, dispatchRedux, getState) {
  return function (dispatch, state) {
    if (Object.keys(state).length) {
      setClubHeader(dispatchRedux, state);
      return;
    }
    if (state?.status && state?.message) {
      return;
    }
    return getClub(slug)
      .then(response => {
        const club = response.data;
        checkApiRedirect(club.slug, slug, null, null);
        dispatch(updateClub(club));
        setClubHeader(dispatchRedux, club);
        return trySettingDefaultDistrict(club, dispatchRedux, getState);
      })
      .catch(function (error) {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          dispatchRedux(updateHttpError(errorData));
        }
      });
  };
}

//  CLUB_TEAMS_PAGE
function updateTeams(data) {
  return {
    type: 'CLUB_TEAMS_UPDATE',
    data,
  };
}

function fetchingTeams() {
  return {
    type: 'CLUB_TEAMS_FETCHING',
  };
}

function fetchClubTeamsSSR(slug) {
  return function (dispatch) {
    return getTeamsByClub(slug)
      .then(response => {
        dispatch(
          updateHistory('undefined', {
            store: 'ClubTeamsPage',
            data: {items: response.data, isFetching: false},
          })
        );
      })
      .catch(function (error) {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchClubTeams(slug, dispatchRedux) {
  return function (dispatch, state) {
    if (state.items.length) {
      return;
    }
    dispatch(fetchingTeams());
    return getTeamsByClub(slug)
      .then(response => {
        dispatch(updateTeams(response.data));
      })
      .catch(function (error) {
        const errorData = catchDataError(error);
        dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

// CLUB_MATCHES_PAGE
function updateMatches(data) {
  return {
    type: 'CLUB_MATCHES_UPDATE',
    data,
  };
}

function errorMatches(data) {
  return {
    type: 'CLUB_MATCHES_ERROR',
    data,
  };
}

function fetchingMatches(scrollDirection) {
  return {
    type: 'CLUB_MATCHES_FETCHING',
    scrollDirection,
  };
}

function overwriteMatches(data) {
  return {
    type: 'CLUB_MATCHES_OVERWRITE',
    data,
  };
}

function fetchClubMatchesSSR(clubSlug, pointer) {
  return function (dispatch) {
    return getClubMatches(clubSlug, pointer)
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        const groupByKickoff = groupMatchesByKickoff(response.data, pointer);
        const items = groupByCompetition(groupByKickoff, clubSlug);

        dispatch(
          updateHistory('undefined', {
            store: 'ClubMatchesPage',
            data: {items, nextUrl, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchClubMatches(clubSlug, pointer, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      return;
    }

    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }
    const fetchFunc = nextUrl ? fetchNextApiCall(nextUrl) : getClubMatches(clubSlug, pointer);

    dispatch(fetchingMatches());
    return fetchFunc
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        const groupByKickoff = groupMatchesByKickoff(response.data, pointer);
        const items = groupByCompetition(groupByKickoff, clubSlug);
        dispatch(updateMatches({items, nextUrl}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        nextUrl ? dispatch(errorMatches(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

//CLUB_NEWS_PAGE
function updateNews(data) {
  return {
    type: 'CLUB_NEWS_UPDATE',
    data,
  };
}

function errorNews(data) {
  return {
    type: 'CLUB_NEWS_ERROR',
    data,
  };
}

function fetchingNews() {
  return {
    type: 'CLUB_NEWS_FETCHING',
  };
}

function overwriteNews(data) {
  return {
    type: 'CLUB_NEWS_OVERWRITE',
    data,
  };
}

function fetchClubNews(slug, categories, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      return;
    }

    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }
    const fetchFunc = nextUrl ? fetchNextApiCall(nextUrl) : getClubStream(slug, categories);
    dispatch(fetchingNews());
    return fetchFunc
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(updateNews({items: response.data, nextUrl, categories}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        nextUrl ? dispatch(errorNews(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchClubNewsSSR(slug, categories) {
  return function (dispatch) {
    dispatch(fetchingNews());
    return getClubStream(slug, categories)
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(
          updateHistory('undefined', {
            store: 'ClubNewsPage',
            data: {items: response.data, nextUrl, categoryFilter: categories, error: null, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

export {
  fetchClubMetaDataSSR,
  fetchClubMetaData,
  fetchClubTeamsSSR,
  fetchClubTeams,
  fetchClubMatchesSSR,
  fetchClubMatches,
  fetchClubNews,
  fetchClubNewsSSR,
  overwriteNews,
  overwriteMatches,
};
