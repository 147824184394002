import React from 'react';
import {COLORS} from '@fupa/fupa-uikit';
import {object} from 'prop-types';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import {Eleven} from 'app/components/icons';
import {ContentTitle, FullStreamContentContainer} from 'app/styles/stream';
import TopElevenStream from 'app/components/topeleven/TopElevenStream';
import {LinkOnComponent} from 'app/components/links/link';

const LeagueTopElevenActivity = ({activity}) => {
  const {competition, round} = activity.activity;
  let linkUrl = `/league/${competition.slug}/season/${competition.seasonSlug}/topeleven/${round}`;

  return (
    <>
      <ActivityHeader
        title='Elf der Woche'
        subtitle={competition.name}
        icon={<Eleven style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.petrol}
        timestamp={activity.timestamp}
        category='elf-der-woche'
      />
      <LinkOnComponent to={linkUrl} rel='nofollow'>
        <div>
          <FullStreamContentContainer>
            <TopElevenStream entity={activity.activity} />
          </FullStreamContentContainer>
          <ContentTitle oneLine>Die Top-Elf des {round}. Spieltags</ContentTitle>
        </div>
      </LinkOnComponent>
    </>
  );
};

LeagueTopElevenActivity.propTypes = {
  activity: object.isRequired,
};

export default LeagueTopElevenActivity;
