import React from 'react';
import {COLORS, Image} from '@fupa/fupa-uikit';
import {object} from 'prop-types';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import {Person, LocalHospital} from '@mui/icons-material';
import {LinkOnComponent} from 'app/components/links/link';
import {
  StreamContentContainer,
  LeftAlignedStreamEntity,
  EntityName,
  ImageContainer,
  ToTeamAlt,
  MultilineInfo,
} from 'app/styles/stream';
import {formatShortDate} from 'app/helpers/dateHelpers';
import {StyledTypography} from 'app/styles/content';
import {ProfileTypography} from 'app/components/profile/ProfileStyles';

const PlayerInjuredActivity = ({activity}) => {
  const {player, teamName, category, duration, from, to} = activity.activity;

  if (!player) {
    return null;
  }

  const isDeactivated = player.isDeactivated;
  let duration_note;
  if (duration === 0 && to) {
    duration_note = formatShortDate(from) + ' – ' + formatShortDate(to);
  } else if (duration === 1) {
    duration_note = duration + ' Woche';
  } else if (duration > 1) {
    duration_note = duration + ' Wochen';
  } else {
    duration_note = 'Dauer offen';
  }

  const PlayerImage = player?.image?.path ? (
    <ImageContainer>
      <Image entity='player' ratio='1:1' width={64} height={64} src={player.image} circle={true} />
    </ImageContainer>
  ) : (
    <ToTeamAlt>
      <Person style={{color: COLORS.lightGrey, fontSize: 64}} />
    </ToTeamAlt>
  );
  const PlayerName = `${player.firstName} ${player.lastName}`;

  const playerContent = (
    <StreamContentContainer>
      <LeftAlignedStreamEntity>
        {PlayerImage}
        <MultilineInfo>
          <EntityName isDeactivated={isDeactivated}>{PlayerName}</EntityName>
          <ProfileTypography isDeactivated={isDeactivated} variant='body2'>
            {category}
          </ProfileTypography>
          <StyledTypography isDeactivated={isDeactivated} variant='caption1' lineHeight={20}>
            {duration_note}
          </StyledTypography>
        </MultilineInfo>
      </LeftAlignedStreamEntity>
    </StreamContentContainer>
  );

  const content = isDeactivated ? (
    playerContent
  ) : (
    <LinkOnComponent to={`/player/${player.slug}`}>{playerContent}</LinkOnComponent>
  );

  return (
    <>
      <ActivityHeader
        title='Verletzung'
        subtitle={teamName || 'N/A'}
        icon={<LocalHospital style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.wineRed}
        timestamp={activity.timestamp}
      />
      {content}
    </>
  );
};

PlayerInjuredActivity.propTypes = {
  activity: object.isRequired,
};

export default PlayerInjuredActivity;
