import React, {useReducer, useEffect} from 'react';
import {ContentAdDesktop} from 'app/styles/content';
import StreamContainer from 'app/components/stream/streamContainer';
import {clubFilters} from 'app/components/stream/streamFilter.const';
import {Helmet} from 'react-helmet-async';
import {object, string} from 'prop-types';
import {useInfiniteScrollComponentState} from 'app/hooks/useInfiniteScroll';
import {StreamFilterFab} from 'app/components/floatingAction/StreamFilterFab';
import {ClubNewsPageReducer} from 'app/routes/club/ClubPageReducer';
import {fetchClubNews, overwriteNews} from 'app/routes/club/ClubPageActions';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {useParams} from 'react-router';
import encodeHTML from 'app/helpers/encodeHTMLInStrings';

const streamRegex = /\/club\/[a-z0-9-]+\//;

const getSchemaMarkup = club => {
  if (!club.slug) {
    return;
  }
  const location = club?.venues.map(venue => {
    return {
      '@type': 'SportsActivityLocation',
      name: venue.name,
      address: {
        '@type': 'PostalAddress',
        postalCode: venue.postcode,
        streetAddress: venue.street,
        addressLocality: venue.city,
      },
      latitude: venue.lat,
      longitude: venue.long,
      photo: {
        '@type': 'ImageObject',
        url: venue.image.path + '640x360.jpeg',
        width: 640,
        height: 360,
      },
    };
  });

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'SportsOrganization',
    sport: 'Soccer',
    name: club.name,
    url: 'https://www.fupa.net/club/' + club.slug,
    logo: {
      '@type': 'ImageObject',
      url: club.image.path + '800x800.png',
      width: 800,
      height: 800,
    },
    location,
    areaServed: club.district.name,
    award: club.achievements,
  };

  return encodeHTML(JSON.stringify(schema));
};

const ClubNewsPage = ({club, districtName, dispatchRedux}) => {
  const {restoredData} = useRestoreData('ClubNewsPage');
  const {clubSlug, categories} = useParams();
  const initialData = {
    isFetching: false,
    items: [],
    nextUrl: '',
    categoryFilter: categories,
    error: null,
  };
  const initialState = restoredData ?? initialData;
  const [state, dispatch] = useReducer(ClubNewsPageReducer, initialState);

  const loadData = (reloading, reset) => {
    const componentState = reset ? initialData : state;
    return fetchClubNews(clubSlug, categories, reloading, dispatchRedux)(dispatch, componentState);
  };

  const {items, isFetching, error, nextUrl, categoryFilter} = state;
  useInfiniteScrollComponentState(loadData, items.length);
  useFetchData(state, loadData, 'ClubNewsPage', true);

  useEffect(() => {
    // Always load new data when switching between filters
    if (categoryFilter !== categories) {
      dispatch(overwriteNews(initialData));
      loadData(false, true);
    }
  }, [categories]);

  const filter = clubFilters.find(filter => filter.param === categories);
  const title = filter ? `${filter.value} - ` : '';
  const meta = [
    {
      name: 'description',
      content: `News ➤ Galerien ➤ Transfers ➤ Videos ➤ Spielabsagen ➤ ${club.name} ➤ ${club.middleName} ➤ ${club.shortName} ➤ ${districtName}`,
    },
    {
      name: 'robots',
      content: 'index',
    },
  ];

  const schema = getSchemaMarkup(club);

  return (
    <ContentAdDesktop
      bgColor='none'
      top='7.125rem'
      fabBtn={<StreamFilterFab filter={clubFilters} regex={streamRegex} />}>
      <Helmet title={title} meta={meta}>
        <script type='application/ld+json'>{schema}</script>
      </Helmet>
      <StreamContainer
        isFetching={isFetching}
        nextPage={nextUrl}
        error={Boolean(error)}
        stream={items}
        adSlots={[]}
        noDesktopAds
      />
    </ContentAdDesktop>
  );
};

ClubNewsPage.propTypes = {
  match: object.isRequired,
  defaultTitle: string.isRequired,
  districtName: string.isRequired,
};

export default ClubNewsPage;
