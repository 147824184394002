import React from 'react';
import styled from 'styled-components';
import {COLORS, SIZES} from '@fupa/fupa-uikit';
import {LinkToCompetition} from 'app/components/links/LinkToCompetition';
import {styled as styledMui} from '@mui/material/styles';
import {Button} from '@mui/material';

const Footer = styled.div`
  display: flex;
  flex: 1 100%;
  padding: 0.25rem 0;
  margin: 0 1rem;
  border-top: ${SIZES['1']} solid ${COLORS.lighterGrey};
  justify-content: space-around;
  color: ${COLORS.darkerGrey};
`;

const SCLinkToCompetition = styled(LinkToCompetition)`
  &&& {
    width: 100%;
  }
`;

const SCMUIButton = styledMui(Button)({
  width: '100%',
  '&:hover, &:focus': {
    backgroundColor: `${COLORS.lighterGrey}`,
  },
});

const MatchCardFooter = ({isLeague, competition}) => {
  const competitionType = isLeague ? 'league' : 'cup';

  const standingsBtn = !isLeague ? null : (
    <SCLinkToCompetition competition={competition} competitionType={competitionType} subpage='standing'>
      <SCMUIButton color='inherit' variant='text'>
        Tabelle
      </SCMUIButton>
    </SCLinkToCompetition>
  );
  const matchesBtn = isLeague ? null : (
    <SCLinkToCompetition competition={competition} competitionType={competitionType} subpage='matches'>
      <SCMUIButton color='inherit' variant='text'>
        Spielplan / Tabelle
      </SCMUIButton>
    </SCLinkToCompetition>
  );

  return competition.slug === 'testspiele' || competition.slug === 'relegation' ? null : (
    <Footer>
      {matchesBtn}
      {standingsBtn}
    </Footer>
  );
};

export {MatchCardFooter};
