import React from 'react';
import {COLORS, Image} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {
  StreamContentContainer,
  LeftAlignedStreamEntity,
  EntityName,
  ImageContainer,
  ToTeamAlt,
  MultilineInfo,
} from 'app/styles/stream';
import {getAge} from 'app/helpers/helpers';
import {object} from 'prop-types';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import {Person} from '@mui/icons-material';
import {Coach} from 'app/components/icons';
import {ProfileTypography} from 'app/components/profile/ProfileStyles';

const CoachChangedActivity = ({activity}) => {
  const isDeactivated = activity.activity.player.isDeactivated;
  const title = activity.activity.type === 'added' ? 'Neuer Trainer' : 'Trainer gestrichen';
  const CoachImage = activity.activity.player?.image?.path ? (
    <ImageContainer>
      <Image entity='player' ratio='1:1' width={64} height={64} src={activity.activity.player.image} circle={true} />
    </ImageContainer>
  ) : (
    <ToTeamAlt>
      <Person style={{color: COLORS.lightGrey, fontSize: 64}} />
    </ToTeamAlt>
  );
  const coachName = activity.activity.player.firstName + ' ' + activity.activity.player.lastName;

  const coachContent = (
    <StreamContentContainer>
      <LeftAlignedStreamEntity>
        {CoachImage}
        <MultilineInfo>
          <EntityName isDeactivated={isDeactivated}>{coachName}</EntityName>
          <ProfileTypography isDeactivated={isDeactivated} variant='body2'>
            {activity.activity.player.birthday ? getAge(activity.activity.player.birthday) : 'keine Altersangabe'}
          </ProfileTypography>
        </MultilineInfo>
      </LeftAlignedStreamEntity>
    </StreamContentContainer>
  );

  const content = isDeactivated ? (
    coachContent
  ) : (
    <LinkOnComponent to={`/player/${activity.activity.player.slug}`}>{coachContent}</LinkOnComponent>
  );
  return (
    <>
      <ActivityHeader
        title={title}
        subtitle={activity.activity.teamName}
        icon={<Coach style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.brown}
        timestamp={activity.timestamp}
      />
      {content}
    </>
  );
};

CoachChangedActivity.propTypes = {
  activity: object.isRequired,
};

export default CoachChangedActivity;
