import React from 'react';
import {COLORS} from '@fupa/fupa-uikit';
import {object} from 'prop-types';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import {Alarm} from '@mui/icons-material';
import MatchEventGoal from 'app/components/matchEvents/MatchEventGoal';
import MatchEventCard from 'app/components/matchEvents/MatchEventCard';
import MatchEventPenaltyFail from 'app/components/matchEvents/MatchEventPenaltyFail';

const MatchEventActivity = ({activity}) => {
  const {homeTeam, awayTeam, type, eventTeamName} = activity.activity;

  const primaryRole = activity.activity.primaryRole
    ? {
        seasonCount: activity.activity.primaryRole.seasonCount,
        player: {...activity.activity.primaryRole},
      }
    : undefined;
  const secondaryRole = activity.activity?.secondaryRole
    ? {
        seasonCount: activity.activity.secondaryRole.seasonCount,
        player: {...activity.activity.secondaryRole},
      }
    : undefined;
  const matchEvent = {...activity.activity, primaryRole, secondaryRole, team: {name: {middle: eventTeamName}}};

  const displayMatchEvents = {
    goal: matchEvent => <MatchEventGoal hideScore={true} key={matchEvent.id} event={matchEvent} />,
    card: matchEvent => <MatchEventCard key={matchEvent.id} event={matchEvent} />,
    penaltyfail: matchEvent => <MatchEventPenaltyFail key={matchEvent.id} event={matchEvent} />,
  };

  const component = displayMatchEvents[type](matchEvent);
  const subtitle = (homeTeam?.name ?? 'N/A') + ' - ' + (awayTeam?.name ?? 'N/A');

  return (
    <>
      <ActivityHeader
        title='Liveticker'
        subtitle={subtitle}
        icon={<Alarm style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.lightBlue}
        match={activity.activity} // MatchEvent is the only type to pass match object instead of timestamp
      />
      {component}
    </>
  );
};

MatchEventActivity.propTypes = {
  activity: object.isRequired,
};

export default MatchEventActivity;
