import React from 'react';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {ContentTitle} from 'app/styles/stream';
import {object} from 'prop-types';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import {InsertComment} from '@mui/icons-material';
import styled from 'styled-components';
import {StreamTeaserImage} from 'app/components/stream/streamTeaserImage';

const HeroContentTitle = styled.div`
  display: none;
  max-width: 500px;
  min-height: 238px;
  position: absolute;
  margin: 0 0 1.5rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.6);

  ${props => props.theme.desktopLayout`
    &&& {
      display: block;
    }
  `}
`;

const SCActivityHeader = styled(ActivityHeader)`
  ${props => props.theme.desktopLayout`
    &&& {
      display: none;
    }
  `}
`;
const SCContentTitle = styled(ContentTitle)`
  ${props => props.theme.desktopLayout`
    &&& {
      display: none;
    }
  `}
`;
const SCLinkOnComponent = styled(LinkOnComponent)`
  ${props => props.theme.desktopLayout`
    &&& {
      display: flex;
      flex-flow: row nowrap;
      text-align: start;
      align-items: flex-end;
      justify-content: flex-start;
    }
  `}
`;

const Category = styled(Typography)`
  &&& {
    line-height: ${SIZES[36]};
    display: block;
    color: ${COLORS.lightestGrey};
  }
`;

const Title = styled(Typography)`
  &&& {
    line-height: ${SIZES[64]};
    display: block;
    color: ${COLORS.lightestGrey};
  }
`;

const NewsPublishedActivity = ({activity, heroLayout}) => {
  const {updatedAt, slug, category, subtitle, image, title} = activity.activity;
  const linkObj = {pathname: `/news/${slug}`, state: {updatedAt}};
  if (heroLayout) {
    return (
      <>
        <SCActivityHeader
          title='News'
          subtitle={category ?? subtitle}
          icon={<InsertComment style={{color: COLORS.white, fontSize: 16}} />}
          iconColor={COLORS.darkGreen}
          timestamp={activity.timestamp}
        />
        <SCLinkOnComponent to={linkObj}>
          <StreamTeaserImage entity='news' image={image} isHero={heroLayout} />
          <SCContentTitle>{title}</SCContentTitle>
          <HeroContentTitle>
            <Category variant='headline2'>{category ?? subtitle}</Category>
            <Title variant='display2'>{title}</Title>
          </HeroContentTitle>
        </SCLinkOnComponent>
      </>
    );
  } else {
    return (
      <>
        <ActivityHeader
          title='News'
          subtitle={category ?? subtitle}
          icon={<InsertComment style={{color: COLORS.white, fontSize: 16}} />}
          iconColor={COLORS.darkGreen}
          timestamp={activity.timestamp}
        />
        <LinkOnComponent to={linkObj}>
          <StreamTeaserImage entity='news' image={image} />
          <ContentTitle>{title}</ContentTitle>
        </LinkOnComponent>
      </>
    );
  }
};

NewsPublishedActivity.propTypes = {
  activity: object.isRequired,
};

export default NewsPublishedActivity;
