import {AdSlot} from 'app/components/ads/AdSlot';
import React from 'react';

const generateMapping = (adSlots, n) => {
  const mapping = {};
  // generates mapping for default values
  // const mapping = {4: 'Content_1', 8: 'Native_1', 12: 'Rectangle_1', 16: 'Content_2', 0: 'Rectangle_2',}
  adSlots.map((adUnit, index) => {
    const position = n * adSlots.length === n * (index + 1) ? 0 : n * (index + 1);
    mapping[position] = adUnit;
  });
  return mapping;
};

const initAdPlacement = (
  elements,
  offset = 0,
  n = 4,
  adSlots = ['Content_1', 'Rectangle_1', 'Content_2', 'Rectangle_2'],
  adLayout
) => {
  const mapping = generateMapping(adSlots, n);
  if (elements) {
    const operator = Object.keys(mapping).length * n;
    const add = offset < 0 ? n + offset : offset;
    const maxLengthToPlaceAdSlots = n * adSlots.length + add;
    const maxLengthOfArray = elements.length < maxLengthToPlaceAdSlots ? elements.length : maxLengthToPlaceAdSlots;
    const keys = elements.length < n ? [n] : Array.from({length: maxLengthOfArray}, (_, i) => i + 1).reverse(); // [20, 19, 18, ... 1]
    keys
      .filter(i => i % n === 0) // [20, 16, 12, 8, 4]
      .map(i => {
        const adUnit = mapping[i % operator];
        elements.splice(i - offset, 0, <AdSlot adLayout={adLayout} key={`ad-${adUnit}`} adUnit={adUnit} />);
      });
    return elements;
  }
};

export {initAdPlacement};
