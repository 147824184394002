const DistrictTransfersPageReducer = (state, action) => {
  switch (action.type) {
    case 'DISTRICT_TRANSFERS_FETCHING':
      return {...state, isFetching: true};
    case 'DISTRICT_TRANSFERS_UPDATE':
      const DistrictTransfersPage = {...state};
      DistrictTransfersPage.items = DistrictTransfersPage.items.concat(action.data.items);
      DistrictTransfersPage.nextUrl = action.data.nextUrl;
      DistrictTransfersPage.isFetching = false;
      return DistrictTransfersPage;
    case 'DISTRICT_TRANSFERS_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: false,
        nextUrl: null,
      };
    default:
      return state;
  }
};

export default DistrictTransfersPageReducer;
