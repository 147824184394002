import styled from 'styled-components';
import {SIZES, Typography} from '@fupa/fupa-uikit';
import {ProfileTypography} from 'app/components/profile/ProfileStyles';

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${SIZES['8']} 0;
  min-width: 6.75rem;
`;

const PlayerData = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`;

const Position = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: ${SIZES['14']};
`;

const CustomName = styled(ProfileTypography)`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: ${SIZES['20']};
  padding-top: ${SIZES['4']};
`;

export {PlayerContainer, PlayerData, Position, CustomName};
