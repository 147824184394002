import React from 'react';
import {COLORS, Image} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {FullStreamContentContainer, ContentTitle} from 'app/styles/stream';
import {object} from 'prop-types';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import {PhotoLibrary} from '@mui/icons-material';
import {styled} from '@mui/material/styles';
import {desktopContentWidth, mobileContentWidth} from 'app/styles/media';

const StyledImage = styled(Image)`
  &&& {
    width: 100vw;
    @media only screen and (min-width: ${mobileContentWidth}px) and (max-width: ${desktopContentWidth}px) {
      width: 640px;
    }
    @media only screen and (min-width: ${desktopContentWidth}px) {
      width: 473px;
    }
  }
`;

const GalleryPublishedActivity = ({activity, isFirstElement}) => {
  return (
    <>
      <ActivityHeader
        title='Galerie'
        subtitle={activity.activity.competitionName}
        icon={<PhotoLibrary style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.deepBlue}
        timestamp={activity.timestamp}
      />
      <LinkOnComponent to={`/photos/${activity.activity.slug}`}>
        <FullStreamContentContainer>
          <StyledImage
            entity='gallery'
            actualWidthFactor={1}
            ratio='16:9'
            src={activity.activity.image}
            eager={isFirstElement}
            sizesHint='(min-width: 970px) 473px, (min-width: 640px) 640px, 100vw'
          />
        </FullStreamContentContainer>
        <ContentTitle>{activity.activity.title}</ContentTitle>
      </LinkOnComponent>
    </>
  );
};

GalleryPublishedActivity.propTypes = {
  activity: object.isRequired,
};

export default GalleryPublishedActivity;
