import React from 'react';
import {Tooltip, Fab} from '@mui/material';
import {PostAdd} from '@mui/icons-material';
import styled from 'styled-components';
import {COLORS} from '@fupa/fupa-uikit';

const Container = styled.div`
  z-index: ${props => props.theme.zIndexFab};
  bottom: 7.5rem;
  right: 0;
  position: fixed;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  ${props => props.theme.desktopLayout`
    bottom: 4rem;
  `}

  ${props => props.theme.desktopLayoutWithFab`
    margin-left: ${props => props.theme.desktopContentWidth}px;
    right: unset;
  `};
`;

const AddMarketplacePostFab = props => {
  const handleClick = () => {
    window.open(`${process.env.ADMIN_URL}/fupa/admin/index.php?page=verein_boerse&aktion=new`);
  };

  return (
    <Container {...props}>
      <Tooltip title='Erstellen' placement='right'>
        <Fab
          sx={{color: COLORS.darkerGrey, backgroundColor: COLORS.white, margin: '1rem'}}
          onClick={handleClick}
          size='small'
          aria-label='add'>
          <PostAdd />
        </Fab>
      </Tooltip>
    </Container>
  );
};

export {AddMarketplacePostFab};
