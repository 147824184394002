import React, {useEffect, useReducer, useState} from 'react';
import {ContentAdDesktop} from 'app/styles/content';
import StreamContainer from 'app/components/stream/streamContainer';
import {useSelector} from 'react-redux';
import {leagueFilters} from 'app/components/stream/streamFilter.const';
import {Helmet} from 'react-helmet-async';
import {string} from 'prop-types';
import {fetchLeagueNews, overwriteNews} from 'app/routes/league/LeaguePageActions';
import {useInfiniteScrollComponentState} from 'app/hooks/useInfiniteScroll';
import {StreamFilterFab} from 'app/components/floatingAction/StreamFilterFab';
import {EditFabContainer} from 'app/components/floatingAction/EditFab';
import {getPermission} from 'app/services/api';
import {selectAuthState} from 'app/hooks/reduxCreateSelectorHooks';
import {useParams} from 'react-router';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {LeagueNewsPageReducer} from 'app/routes/league/LeaguePageReducer';

const streamRegex = /\/league\/[a-z0-9-]+(\/season\/\d+-\d+)?\/?/;

const LeagueNewsPage = ({defaultTitle, districtName, leagueId, hasLeagueData, competitionSeasonId, dispatchRedux}) => {
  const {restoredData} = useRestoreData('LeagueNewsPage');
  const {leagueSlug, seasonSlug, categories} = useParams();

  const initialData = {
    isFetching: false,
    items: [],
    nextUrl: '',
    categoryFilter: categories,
    error: null,
  };
  const initialState = restoredData ?? initialData;
  const [state, dispatch] = useReducer(LeagueNewsPageReducer, initialState);

  const loadData = (reloading, reset) => {
    const componentState = reset ? initialData : state;
    return fetchLeagueNews(leagueSlug, seasonSlug, categories, reloading, dispatchRedux)(dispatch, componentState);
  };

  const isAuthenticated = useSelector(selectAuthState);
  const [isAuthorised, setIsAuthorised] = useState(false);
  const {items, isFetching, nextUrl, error, categoryFilter} = state;
  useInfiniteScrollComponentState(loadData, items.length);
  useFetchData(state, loadData, 'LeagueNewsPage', true);

  useEffect(() => {
    // Always load new data when switching between filters
    if (categoryFilter !== categories) {
      dispatch(overwriteNews(initialData));
      loadData(false, true);
    }
  }, [categories]);

  const filter = leagueFilters.find(filter => filter.param === categories);
  const title = filter ? `${filter.value} - ` : '';
  const meta = [
    {
      name: 'description',
      content: `Alle Spiele, Fußball-News, Ergebnisse, Tabellen, Liveticker und die Elf der Woche zur ${defaultTitle} - Kreis ${districtName}.`,
    },
  ];

  useEffect(() => {
    if (leagueId && !isAuthorised) {
      getPermission('competition', leagueId)
        .then(response => setIsAuthorised(response.data?.edit))
        .catch(error => {
          if (error.status === 401) {
            setIsAuthorised(false);
          }
        });
    } else if (!isAuthenticated && isAuthorised) {
      setIsAuthorised(false);
    }
  }, [leagueId, isAuthenticated]);

  if (!hasLeagueData) {
    return null;
  }

  const editBtn =
    isAuthorised && competitionSeasonId ? (
      <EditFabContainer
        secondary
        url={`${process.env.ADMIN_URL}/fupa/admin/index.php?page=liga&liga=${competitionSeasonId}`}
      />
    ) : null;
  const adSlots = ['Native_1', 'Rectangle_1', 'Content_2', 'Rectangle_2'];
  return (
    <ContentAdDesktop
      bgColor='none'
      top='7.125rem'
      fabBtn={
        <>
          {editBtn}
          <StreamFilterFab filter={leagueFilters} regex={streamRegex} />
        </>
      }>
      <Helmet title={title} meta={meta} />
      <StreamContainer
        adSlots={adSlots}
        isFetching={isFetching}
        nextPage={nextUrl}
        error={Boolean(error)}
        stream={items}
        desktopNative_1
      />
    </ContentAdDesktop>
  );
};

LeagueNewsPage.propTypes = {
  defaultTitle: string.isRequired,
  districtName: string.isRequired,
};

export default LeagueNewsPage;
