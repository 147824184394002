import styled from 'styled-components';
import {COLORS, SIZES} from '@fupa/fupa-uikit';
import {FlexContainer} from 'app/styles/content';

const MainContainer = styled.div`
  background-color: ${COLORS.darkestGrey};
  padding: ${SIZES['6']};
`;

const AdPlaceholder = styled.div`
  width: 100%;
  padding-top: 15.625%;
  background-color: ${COLORS.grey};
`;

const Field = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: inherit;
  min-height: 8rem;
  background-color: ${COLORS.darkGreen};
  margin: ${props => (props.stream ? '0' : SIZES['8'])} 0};
`;

const FieldLine = styled(FlexContainer)`
  justify-content: center;
  width: 100%;
  height: inherit;
  min-height: 8rem;
  border: ${SIZES['4']} solid rgba(255, 255, 255, 0.2);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
`;

const Goal = styled(FlexContainer)`
  width: 100%;
  position: absolute;
  top: ${SIZES['4']};
  left: 0;
  justify-content: center;
  opacity: 0.2;
`;

const FuPaPlayer = styled.div`
  position: absolute;
  height: ${props => (props.height ? props.height : '16.875rem')};
  width: inherit;
  padding-left: 66.67%;
  top: ${SIZES['32']};
  opacity: 0.1;

  svg {
    object-fit: cover;
    width: inherit;
    height: inherit;
  }
`;

const MidLine = styled.div`
  height: ${SIZES['4']};
  width: 100%;
  position: absolute;
  top: 18.75rem;
  opacity: 0.2;
  background-image: url('/midline.svg');
  background-repeat: repeat;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  -moz-background-clip: padding; /* Firefox 3.6 */
  -webkit-background-clip: padding; /* Safari 4? Chrome 6? */
  background-clip: padding-box; /* Firefox 4, Safari 5, Opera 10, IE 9 */
`;

export {MainContainer, AdPlaceholder, Field, FieldLine, Goal, FuPaPlayer, MidLine};
