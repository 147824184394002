import React from 'react';
import styled from 'styled-components';
import {COLORS, Image, Typography} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {StreamContentContainer, StreamEntity, EntityName, ImageContainer, ToTeamAlt} from 'app/styles/stream';
import {object} from 'prop-types';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import {Check} from '@mui/icons-material';
import {Emblem} from 'app/components/icons';

const Result = styled(Typography)`
  letter-spacing: -0.21875rem;
`;

const MatchResultActivity = ({activity}) => {
  const homeTeamName = activity.activity.homeTeam?.name?.middle || 'N/A';
  const awayTeamName = activity.activity.awayTeam?.name?.middle || 'N/A';
  const subtitle = `${homeTeamName} - ${awayTeamName}`;

  const HomeTeam = activity.activity.homeTeam?.image ? (
    <ImageContainer>
      <Image entity='emblem' width={64} height={64} src={activity.activity.homeTeam.image} fit={true} />
    </ImageContainer>
  ) : (
    <ToTeamAlt>
      <Emblem style={{color: COLORS.lightGrey, fontSize: 64}} />
    </ToTeamAlt>
  );
  const AwayTeam = activity.activity.awayTeam?.image ? (
    <ImageContainer>
      <Image entity='emblem' width={64} height={64} src={activity.activity.awayTeam.image} fit={true} />
    </ImageContainer>
  ) : (
    <ToTeamAlt>
      <Emblem style={{color: COLORS.lightGrey, fontSize: 64}} />
    </ToTeamAlt>
  );

  return (
    <>
      <ActivityHeader
        title='Endstand'
        subtitle={subtitle}
        icon={<Check style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.asphalt}
        timestamp={activity.timestamp}
      />
      <LinkOnComponent to={`/match/${activity.activity.slug}`}>
        <StreamContentContainer>
          <StreamEntity>
            {HomeTeam}
            <Result variant='display2'>
              {activity.activity.homeGoal} : {activity.activity.awayGoal}
            </Result>
            {AwayTeam}
          </StreamEntity>
          <EntityName>{activity.activity.competitionName ?? 'ohne Wettbewerb'}</EntityName>
        </StreamContentContainer>
      </LinkOnComponent>
    </>
  );
};

MatchResultActivity.propTypes = {
  activity: object.isRequired,
};

export default MatchResultActivity;
