import React from 'react';
import styled from 'styled-components';
import {COLORS, Image, Typography} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {StreamContentContainer, StreamEntity, EntityName, ImageContainer, ToTeamAlt} from 'app/styles/stream';
import {object} from 'prop-types';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import {Report} from '@mui/icons-material';
import {Emblem} from 'app/components/icons';
import {formatLongDate} from 'app/helpers/dateHelpers';

const Container = styled(StreamContentContainer)`
  text-align: center;
`;

const types = {
  rescheduled: {
    title: 'Spielverlegung',
    text: 'Spiel verlegt',
  },
  canceled: {
    title: 'Spielabsage',
    text: 'Spiel abgesagt',
  },
  aborted: {
    title: 'Spielabbruch',
    text: 'Spiel abgebrochen',
  },
};

const MatchKickoffChangedActivity = ({activity}) => {
  const homeTeamName = activity.activity.homeTeam?.name?.middle || 'N/A';
  const awayTeamName = activity.activity.awayTeam?.name?.middle || 'N/A';
  const subtitle = `${homeTeamName} - ${awayTeamName}`;
  const type = activity.activity.type;
  const title = types[type].title;
  const text = types[type].text;
  let description;
  if (type === 'rescheduled') {
    description = `Vom ${formatLongDate(activity.activity.old_kickoff)} Uhr auf den ${formatLongDate(
      activity.activity.new_kickoff
    )} Uhr`;
  }

  const HomeTeam = activity.activity.homeTeam?.image ? (
    <ImageContainer>
      <Image entity='emblem' width={64} height={64} src={activity.activity.homeTeam.image} fit={true} />
    </ImageContainer>
  ) : (
    <ToTeamAlt>
      <Emblem style={{color: COLORS.lightGrey, fontSize: 64}} />
    </ToTeamAlt>
  );
  const AwayTeam = activity.activity.awayTeam?.image ? (
    <ImageContainer>
      <Image entity='emblem' width={64} height={64} src={activity.activity.awayTeam.image} fit={true} />
    </ImageContainer>
  ) : (
    <ToTeamAlt>
      <Emblem style={{color: COLORS.lightGrey, fontSize: 64}} />
    </ToTeamAlt>
  );

  return (
    <>
      <ActivityHeader
        title={title}
        subtitle={subtitle}
        icon={<Report style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.yellow}
        timestamp={activity.timestamp}
      />
      <LinkOnComponent to={`/match/${activity.activity.slug}`}>
        <Container>
          <StreamEntity>
            {HomeTeam}
            {AwayTeam}
          </StreamEntity>
          <EntityName>{text}</EntityName>
          <Typography variant='body2'>{description}</Typography>
        </Container>
      </LinkOnComponent>
    </>
  );
};

MatchKickoffChangedActivity.propTypes = {
  activity: object.isRequired,
};

export default MatchKickoffChangedActivity;
