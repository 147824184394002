import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Popover} from '@mui/material';
import {SIZES, Typography} from '@fupa/fupa-uikit';
import {arrayOf, bool, func, shape, string} from 'prop-types';
import {useHistory, useParams} from 'react-router';

const Menu = styled.div`
  min-width: ${SIZES['148']};
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  display: inline-block;
  z-index: 100;
  overflow: auto;
  @media only screen and (max-device-height: 759px) {
    max-height: ${SIZES['400']};
  }
  @media only screen and (max-device-height: 600px) {
    max-height: 300px;
  }
`;

const FilterMenuContent = ({filterOptions, onChange, ...props}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const {categories} = useParams();

  useEffect(() => {
    if (categories) {
      setSelectedValue(categories);
    } else {
      setSelectedValue('no-filter');
    }
  }, [categories]);

  const handleChange = event => {
    const value = event.target.value;
    setSelectedValue(value);
    onChange(value);
  };

  const Items = filterOptions.map(option => {
    return (
      <FormControlLabel
        sx={{
          margin: 0,
        }}
        key={option.param}
        value={option.param}
        control={<Radio sx={{padding: '0.25rem 0.5rem'}} />}
        label={option.value}
      />
    );
  });

  return (
    <Menu {...props}>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>
          <Typography variant='caption3'>Kategorien</Typography>
        </FormLabel>
        <RadioGroup aria-label='filter' name='filter' value={selectedValue} onChange={handleChange}>
          <FormControlLabel
            sx={{margin: 0}}
            key='no-filter'
            value='no-filter'
            control={<Radio sx={{padding: '0.25rem 0.5rem'}} />}
            label='Alle'
          />
          {Items}
        </RadioGroup>
      </FormControl>
    </Menu>
  );
};

const setStreamFilterLocation = (option, regex, path, search) => {
  let urlQuery = option === 'no-filter' ? null : option;
  let streamUrl = path;
  let pathname = streamUrl.match(regex);
  streamUrl = pathname ? pathname[0].substr(0, pathname[0].length) : streamUrl;
  streamUrl = streamUrl.endsWith('/') ? streamUrl.substr(0, streamUrl.length - 1) : streamUrl;
  const mainUrl = urlQuery ? streamUrl + `/${urlQuery}` : streamUrl;
  return search ? mainUrl + search : mainUrl;
};

const FilterMenu = ({transformOrigin, anchorOrigin, open, filter, regex, onCallback, anchorEl, ...props}) => {
  const history = useHistory();
  const updatePage = option => {
    const streamUrl = history.location.pathname;
    const searchQuery = history.location.search;
    let URLWithFilter = setStreamFilterLocation(option, regex, streamUrl, searchQuery);
    history.push(URLWithFilter);
    onCallback();
  };

  return (
    <Popover
      id='simple-popper'
      open={open}
      onClose={onCallback}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}>
      <FilterMenuContent onChange={updatePage} filterOptions={filter} {...props} />
    </Popover>
  );
};

FilterMenuContent.propTypes = {
  /**
   * Array of objects for every Checkbox of the Container
   */
  filterOptions: arrayOf(
    shape({
      value: string,
      checked: bool,
    })
  ).isRequired,
  /**
   * Callback with selected filter-value when selected value is changed
   */
  onChange: func,
};

export {FilterMenu};
