import React, {useMemo} from 'react';
import {COLORS, Image} from '@fupa/fupa-uikit';
import {Emblem} from 'app/components/icons';
import {formatShortDate} from 'app/helpers/dateHelpers';
import {HeaderSponsoring} from 'app/components/sponsoring/HeaderSponsoring';
import {LinkOnComponent} from 'app/components/links/link';
import {
  StreamIcon,
  Category,
  Container,
  Header,
  InfoColumn,
  Line,
  Timestamp,
  MessageHeading,
  CategoryName,
} from 'app/styles/stream';
import MatchResult from 'app/components/matchRow/MatchResult';
import {MatchResultModel} from 'app/models/match/MatchResultModel';

const getImage = image =>
  image ? (
    <Image entity='emblem' src={image} width={20} height={20} fit={true} />
  ) : (
    <Emblem style={{color: COLORS.lightGrey, fontSize: 20}} />
  );

const ActivityHeader = ({className, title, subtitle, icon, iconColor, category, timestamp, match}) => {
  const matchResult = useMemo(() => (match ? new MatchResultModel(match) : null), [match]);
  let infoRight;
  if (match) {
    infoRight = (
      <LinkOnComponent to={`/match/${match.matchSlug}`}>
        <InfoColumn>
          {getImage(match?.homeTeam?.image)}
          <MatchResult matchResult={matchResult} allowLive={false} color={COLORS.lightestGrey} />
          {getImage(match?.awayTeam?.image)}
        </InfoColumn>
      </LinkOnComponent>
    );
  } else {
    infoRight = <Timestamp>{formatShortDate(timestamp)}</Timestamp>;
  }

  return (
    <Header variant='caption3' component='div' className={className}>
      <Container>
        <StreamIcon color={iconColor}>{icon}</StreamIcon>
        <Category>
          <CategoryName>{title}</CategoryName>
          <MessageHeading>{subtitle}</MessageHeading>
        </Category>
        {category && <HeaderSponsoring category={category} />}
        {infoRight}
      </Container>
      <Line />
    </Header>
  );
};

export default ActivityHeader;
