const DistrictNewsPageReducer = (state, action) => {
  switch (action.type) {
    case 'DISTRICT_NEWS_FETCHING':
      return {...state, isFetching: true};
    case 'DISTRICT_NEWS_UPDATE':
      const DistrictNewsPage = {...state};
      DistrictNewsPage.items = DistrictNewsPage.items.concat(action.data.items);
      DistrictNewsPage.nextUrl = action.data.nextUrl;
      DistrictNewsPage.isFetching = false;
      DistrictNewsPage.categoryFilter = action.data.categories;
      return DistrictNewsPage;
    case 'DISTRICT_NEWS_OVERWRITE':
      return action.data;
    case 'DISTRICT_NEWS_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: false,
        nextUrl: null,
      };
    default:
      return state;
  }
};

const DistrictMarketplacePageReducer = (state, action) => {
  switch (action.type) {
    case 'DISTRICT_MARKETPLACE_FETCHING':
      return {...state, isFetching: true};
    case 'DISTRICT_MARKETPLACE_UPDATE':
      const DistrictMarketplacePage = {...state};
      DistrictMarketplacePage.items = DistrictMarketplacePage.items.concat(action.data.items);
      DistrictMarketplacePage.nextUrl = action.data.nextUrl;
      DistrictMarketplacePage.isFetching = false;
      DistrictMarketplacePage.categoryFilter = action.data.categories;
      return DistrictMarketplacePage;
    case 'DISTRICT_MARKETPLACE_OVERWRITE':
      return action.data;
    case 'DISTRICT_MARKETPLACE_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: false,
        nextUrl: null,
      };
    default:
      return state;
  }
};

export {DistrictNewsPageReducer, DistrictMarketplacePageReducer};
