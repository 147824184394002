import {useEffect} from 'react';
import {captureSentryException} from 'app/services/sentryLogging';

export const THRESHOLD_1_DAY_IN_MS = 1000 * 60 * 60 * 24;

export const useLogOutdatedContent = (maxAge = THRESHOLD_1_DAY_IN_MS) => {
  useEffect(() => {
    const handlePageShow = event => {
      if (event.persisted) {
        // Page was restored from cache
        // Check if data is up-to-date
        const htmlTimestamp = document.getElementById('last-timestamp')?.textContent;
        const currentTimestamp = new Date().getTime();
        if (htmlTimestamp && currentTimestamp - Number(htmlTimestamp) > maxAge) {
          captureSentryException(new Error('Site from cache expired'), {
            extra: {htmlTimestamp, currentTimestamp, difference: msToTime(currentTimestamp - htmlTimestamp)},
          });
        }
      }
    };
    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);
};

const msToTime = ms => {
  let seconds = (ms / 1000).toFixed(1);
  let minutes = (ms / (1000 * 60)).toFixed(1);
  let hours = (ms / (1000 * 60 * 60)).toFixed(1);
  let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
  if (seconds < 60) return seconds + ' s';
  else if (minutes < 60) return minutes + ' min';
  else if (hours < 24) return hours + ' h';
  else return days + ' d';
};
