import React, {useReducer} from 'react';
import {ContentAdDesktop} from 'app/styles/content';
import StreamContainer from 'app/components/stream/streamContainer';
import {Helmet} from 'react-helmet-async';
import {useInfiniteScrollComponentState} from 'app/hooks/useInfiniteScroll';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {useParams} from 'react-router';
import DistrictTransfersPageReducer from 'app/routes/district/DistrictTransfersPageReducer';
import {fetchDistrictTransfers} from 'app/routes/district/DistrictTransferPageActions';
import StickyMobileBannerAdSlot from 'app/components/ads/StickyMobileBannerAdSlot';

const DistrictTransfersPage = ({name, dispatchRedux}) => {
  const {restoredData} = useRestoreData('DistrictTransfersPage');
  const {districtSlug} = useParams();
  const initialData = {
    isFetching: false,
    items: [],
    nextUrl: '',
    error: null,
  };
  const initialState = restoredData ?? initialData;
  const [state, dispatch] = useReducer(DistrictTransfersPageReducer, initialState);
  const loadData = reloading => {
    return fetchDistrictTransfers(districtSlug, reloading, dispatchRedux)(dispatch, state);
  };

  const {items, isFetching, nextUrl, error} = state;
  useInfiniteScrollComponentState(loadData, items.length);
  useFetchData(state, loadData, 'DistrictTransfers');

  const title = 'Wechselbörse: Alle Transfers in';
  const meta = [
    {
      name: 'description',
      content: `${name} Wechselbörse: Alle Transfers - Aktuelle Transfermeldungen und Trainerwechsel über einen Neuzugang oder Abgang in den Teams des Fußballkreises ${name} in der Übersicht.`,
    },
    {
      name: 'robots',
      content: 'index, follow',
    },
  ];

  return (
    <>
      <ContentAdDesktop bgColor='none' top='7.125rem'>
        <Helmet title={title} meta={meta} />
        <StreamContainer
          isFetching={isFetching}
          error={Boolean(error)}
          nextPage={nextUrl}
          stream={items}
          adSlots={[]}
          noDesktopAds
        />
      </ContentAdDesktop>
      <StickyMobileBannerAdSlot />
    </>
  );
};

export default DistrictTransfersPage;
