import React from 'react';
import {COLORS, Image, IconBadge} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {
  StreamContentContainer,
  LeftAlignedStreamEntity,
  EntityName,
  ImageContainer,
  ToTeamAlt,
  MultilineInfo,
} from 'app/styles/stream';
import {formatShortDate} from 'app/helpers/dateHelpers';
import {StyledTypography} from 'app/styles/content';
import {Person} from '@mui/icons-material';
import {Ban, Card, TwoCards} from 'app/components/icons';
import {object} from 'prop-types';
import ActivityHeader from 'app/components/stream/activities/activityHeader';

import {ProfileTypography} from 'app/components/profile/ProfileStyles';

const PlayerBannedActivity = ({activity}) => {
  const {player, teamName, description, matches, from, to, type} = activity.activity;
  const isDeactivated = player?.isDeactivated;
  const playerName = `${player.firstName} ${player.lastName}`;
  const subtitle = teamName || 'N/A';
  const note = description || 'Kein Grund angegeben';
  let duration;
  if (matches === 0 && to) {
    duration = formatShortDate(from) + ' – ' + formatShortDate(to);
  } else if (matches === 1) {
    duration = matches + ' Spiel';
  } else if (matches > 1) {
    duration = matches + ' Spiele';
  } else {
    duration = 'Dauer offen';
  }

  const PlayerImage = player?.image?.baseName ? (
    <Image entity='player' ratio='1:1' width={64} height={64} src={player.image} circle={true} />
  ) : (
    <ToTeamAlt>
      <Person style={{color: COLORS.lightGrey, fontSize: 64}} />
    </ToTeamAlt>
  );

  const setBadgeValue = {
    red: <Card style={{color: COLORS.red, fontSize: 12}} />,
    yellowred: <TwoCards style={{fontSize: 12}} />,
    yellow: <Card style={{color: COLORS.yellowCard, fontSize: 12}} />,
  };

  const badgeValue = setBadgeValue[type];

  const badge = badgeValue ? <IconBadge value={badgeValue} position={1} /> : null;

  const playerContent = (
    <StreamContentContainer>
      <LeftAlignedStreamEntity>
        <ImageContainer>
          {PlayerImage}
          {badge}
        </ImageContainer>
        <MultilineInfo>
          <EntityName isDeactivated={isDeactivated}>{playerName}</EntityName>
          <ProfileTypography isDeactivated={isDeactivated} variant='body2'>
            {note}
          </ProfileTypography>
          <StyledTypography isDeactivated={isDeactivated} variant='caption1' lineHeight={20}>
            {duration}
          </StyledTypography>
        </MultilineInfo>
      </LeftAlignedStreamEntity>
    </StreamContentContainer>
  );

  const content = isDeactivated ? (
    playerContent
  ) : (
    <LinkOnComponent to={`/player/${activity.activity.player.slug}`}>{playerContent}</LinkOnComponent>
  );
  return (
    <>
      <ActivityHeader
        title='Sperre'
        subtitle={subtitle}
        icon={<Ban style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.green}
        timestamp={activity.timestamp}
      />
      {content}
    </>
  );
};

PlayerBannedActivity.propTypes = {
  activity: object.isRequired,
};

export default PlayerBannedActivity;
