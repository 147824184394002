import React from 'react';
import styled from 'styled-components';
import {COLORS, TextBadge, Image, Typography} from '@fupa/fupa-uikit';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import {object} from 'prop-types';
import {Leaderboard} from 'app/components/icons';
import {StreamContentContainer, StreamEntity, ImageContainer} from 'app/styles/stream';
import {CustomName, PlayerContainer, PlayerData, Position} from 'app/components/LineUp/LineUpPlayerStyles';
import {getPlayerShortName} from 'app/helpers/helpers';
import {formatLongDate} from 'app/helpers/dateHelpers';
import {LinkOnComponent} from 'app/components/links/link';
import {generateBasicCompetitionLink} from 'app/helpers/actionsHelpers';

const Container = styled(StreamContentContainer)`
  &&& {
    padding: 1rem 0;
  }
`;

const Scorers = styled(StreamEntity)`
  flex: 1;

  & ${PlayerContainer}:nth-child(2) {
    margin-bottom: 1.5rem;
  }
`;

const Status = styled.div`
  align-self: flex-end;
  padding: 0 1rem;
`;

const CompetitionScorersActivity = ({activity}) => {
  const {competition, firstScorer, secondScorer, thirdScorer, createdAt} = activity.activity;
  let linkUrl = `${generateBasicCompetitionLink(competition)}/scorers`;

  if (!firstScorer || !secondScorer || !thirdScorer) {
    return null;
  }

  const generateScorer = (value, player) => {
    return (
      <PlayerContainer>
        <ImageContainer>
          <TextBadge value={value} position={2} />
          <Image entity='player' ratio='1:1' width={64} height={64} circle={true} src={player.image} />
        </ImageContainer>
        <PlayerData>
          <CustomName variant='body1'>{getPlayerShortName(player)}</CustomName>
          <Position>
            <Typography variant='caption1'>{player.teamName}</Typography>
          </Position>
        </PlayerData>
      </PlayerContainer>
    );
  };

  const first = generateScorer('🥇', firstScorer);
  const second = generateScorer('🥈', secondScorer);
  const third = generateScorer('🥉', thirdScorer);

  return (
    <>
      <ActivityHeader
        title='Torjäger'
        subtitle={competition.name || 'N/A'}
        icon={<Leaderboard style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.smoke}
        timestamp={activity.timestamp}
        category='competition-scorers'
      />
      <LinkOnComponent to={linkUrl} rel='nofollow'>
        <Container>
          <Scorers>
            {second}
            {first}
            {third}
          </Scorers>
          <Status>
            <Typography variant='caption1'>Stand: {formatLongDate(createdAt)} Uhr</Typography>
          </Status>
        </Container>
      </LinkOnComponent>
    </>
  );
};

CompetitionScorersActivity.propTypes = {
  activity: object.isRequired,
};

export default CompetitionScorersActivity;
