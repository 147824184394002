import React, {useState} from 'react';
import styled from 'styled-components';
import {Fab, Tooltip} from '@mui/material';
import {FilterList} from '@mui/icons-material';
import {FilterMenu} from 'app/components/floatingAction/FilterMenu';

const Container = styled.div`
  z-index: ${props => props.theme.zIndexFab};
  bottom: 4rem;
  right: 0;
  position: fixed;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  ${props => props.theme.desktopLayout`
    bottom: 0.5rem;
  `};

  ${props => props.theme.desktopLayoutWithFab`
    margin-left: ${props => props.theme.desktopContentWidth}px;
    right: unset;
  `};
`;

const StreamFilterFab = ({filter, regex, ...props}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const transformOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  };

  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  return (
    <Container {...props}>
      <FilterMenu
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={open}
        anchorEl={anchorEl}
        filter={filter}
        regex={regex}
        onCallback={handleClose}
        {...props}
      />
      <Tooltip title='Filtern' placement='right'>
        <Fab sx={{margin: '1rem'}} color='secondary' onClick={handleClick} size='small' aria-label='filter'>
          <FilterList />
        </Fab>
      </Tooltip>
    </Container>
  );
};

export {StreamFilterFab};
