const ClubPageReducer = (state, action) => {
  switch (action.type) {
    case 'CLUB_UPDATE':
      return action.data;
    default:
      return state;
  }
};

const ClubNewsPageReducer = (state, action) => {
  switch (action.type) {
    case 'CLUB_NEWS_FETCHING':
      return {...state, isFetching: true};
    case 'CLUB_NEWS_UPDATE':
      const ClubNewsPage = {...state};
      ClubNewsPage.items = ClubNewsPage.items.concat(action.data.items);
      ClubNewsPage.nextUrl = action.data.nextUrl;
      ClubNewsPage.isFetching = false;
      ClubNewsPage.categoryFilter = action.data.categories;
      return ClubNewsPage;
    case 'CLUB_NEWS_OVERWRITE':
      return action.data;
    case 'CLUB_NEWS_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: null,
        nextUrl: null,
      };
    default:
      return state;
  }
};

const ClubTeamsPageReducer = (state, action) => {
  switch (action.type) {
    case 'CLUB_TEAMS_FETCHING':
      return {...state, isFetching: true};
    case 'CLUB_TEAMS_UPDATE':
      return {...state, items: action.data, isFetching: false};
    default:
      return state;
  }
};

const ClubMatchesPageReducer = (state, action) => {
  switch (action.type) {
    case 'CLUB_MATCHES_FETCHING':
      return {...state, isFetching: true};
    case 'CLUB_MATCHES_OVERWRITE':
      return action.data;
    case 'CLUB_MATCHES_UPDATE':
      const ClubMatchesPage = {...state};
      ClubMatchesPage.isFetching = false;
      ClubMatchesPage.items = ClubMatchesPage.items.concat(action.data.items);
      ClubMatchesPage.nextUrl = action.data.nextUrl;

      return ClubMatchesPage;
    case 'CLUB_MATCHES_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: null,
        nextUrl: null,
      };
    default:
      return state;
  }
};

export {ClubPageReducer, ClubNewsPageReducer, ClubTeamsPageReducer, ClubMatchesPageReducer};
