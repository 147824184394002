import React, {useReducer} from 'react';
import {ContentAdDesktop} from 'app/styles/content';
import StreamContainer from 'app/components/stream/streamContainer';
import {useSelector} from 'react-redux';
import {Helmet} from 'react-helmet-async';
import {object, string} from 'prop-types';
import {fetchTeamTransfers} from 'app/routes/team/TeamPageActions';
import {useInfiniteScrollComponentState} from 'app/hooks/useInfiniteScroll';
import {selectAuthState} from 'app/hooks/reduxCreateSelectorHooks';
import {EditFabContainer} from 'app/components/floatingAction/EditFab';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {useParams} from 'react-router';
import {TeamTransferPageReducer} from 'app/routes/team/TeamPageReducer';

const TeamTransferPage = ({team, defaultMetaDesc, dispatchRedux}) => {
  const {restoredData} = useRestoreData('TeamTransferPage');
  const {teamSlug} = useParams();
  const initialData = {
    isFetching: false,
    items: [],
    nextUrl: '',
    error: null,
  };

  const initialState = restoredData ?? initialData;
  const [state, dispatch] = useReducer(TeamTransferPageReducer, initialState);

  const loadData = (reloading, reset) => {
    const componentState = reset ? initialData : state;
    return fetchTeamTransfers(teamSlug, reloading, dispatchRedux)(dispatch, componentState);
  };

  const {items, isFetching, nextUrl, error} = state;
  useInfiniteScrollComponentState(loadData, items.length);
  useFetchData(state, loadData, 'TeamTransferPage');

  const isAuthenticated = useSelector(selectAuthState);
  const title = 'Spielerwechsel - ';
  const meta = [{name: 'description', content: `${title}${defaultMetaDesc}`}];

  const editBtn =
    isAuthenticated && team?.id ? (
      <EditFabContainer url={`${process.env.ADMIN_URL}/fupa/admin/index.php?page=team_transfers&team=${team.id}`} />
    ) : null;

  return (
    <ContentAdDesktop bgColor='none' top='7.125rem' fabBtn={editBtn}>
      <Helmet title={title} meta={meta} />
      <StreamContainer isFetching={isFetching} nextPage={nextUrl} error={Boolean(error)} stream={items} />
    </ContentAdDesktop>
  );
};

TeamTransferPage.propTypes = {
  match: object.isRequired,
  defaultMetaDesc: string.isRequired,
};

export default TeamTransferPage;
