import {getElementFromLocalStorage, setElementToLocalStorage} from 'app/helpers/webStorageUtility';

/**
 * Move item from a specific index to the start of an array
 *
 * @param {object[]} array the array to operate on
 * @param {number} index the index of the item to move
 * @returns {object[]} a new array with specific item at index 0
 */
const moveItemInArrayToStart = (array, index) => {
  // return if no move is necessary
  if (index === 0) return array;
  // create a new array to avoid mutation and be pure
  const newArray = [...array];
  const target = newArray[index];
  // remove item from array
  newArray.splice(index, 1);
  // re-add item at the start of the array
  newArray.unshift(target);
  return newArray;
};

/**
 * Update String of last visited items (currently Districts and Leagues) to store it in a cookie
 * Update could either be adding, moving and/or removing items
 *
 * @param {object[]} lastState list of items stored in specific "last visited items"-localStorage
 * @param {object} newItem the item that should be updated in "last visited items"
 * @param {number} maxLength the maximum number of items stored in "last visited items"
 * @returns {object[]} the new value of "last visited items"
 */
const updateLastItems = (lastState, newItem, maxLength) => {
  let currentState = lastState ? [...lastState] : [];
  // make array from lastState-String to simplify handling
  const isInCurrentState = currentState.some(item => item.slug === newItem.slug);
  if (!isInCurrentState) {
    // If not in currentState, add newItem at start of currentState
    currentState.unshift(newItem);
  } else {
    // If already in currentState, move newItem to start of currentState
    currentState = moveItemInArrayToStart(
      currentState,
      currentState.indexOf(currentState.find(item => item.slug === newItem.slug))
    );
  }
  if (currentState.length > maxLength) {
    // If currentState exceeds maxLength, remove the last item of currentState
    currentState.pop();
  }
  // Return currentState as String separated by separator to easily store it in cookie
  return currentState;
};

export const updateLastCompetitions = newCompetition => {
  const currentLastCompetitionsStorage = getElementFromLocalStorage('last-competitions');
  const currentLastCompetitions = currentLastCompetitionsStorage ? JSON.parse(currentLastCompetitionsStorage) : [];
  const newLastCompetitions = updateLastItems(currentLastCompetitions, newCompetition, 5);
  setElementToLocalStorage('last-competitions', JSON.stringify(newLastCompetitions));
};

export const updateLastDistricts = newDistrict => {
  const currentLastDistrictsStorage = getElementFromLocalStorage('last-districts');
  const currentLastDistricts = currentLastDistrictsStorage ? JSON.parse(currentLastDistrictsStorage) : [];
  const newLastDistricts = updateLastItems(currentLastDistricts, newDistrict, 7);
  setElementToLocalStorage('last-districts', JSON.stringify(newLastDistricts));
};
